import {Component, OnInit} from '@angular/core';
import {SidenavService} from "../../account-sidenav/sidenav.service";

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})
export class RegisterPageComponent implements OnInit {

  constructor(private sidenavService: SidenavService) { }

  ngOnInit(): void {
  }

  expandSidenavOnChangeToLogin() {
    this.sidenavService.expand()
  }

}
