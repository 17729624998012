import {Component, Input, OnInit} from "@angular/core";
import {AuthService} from "../auth.service";
import {UtilsService} from "../../utils.service";
import {AccountResource, NotificationResource} from "../../generated/cronos/resources";
import {AccountInfoData, DisplayAccountNotificationData} from "../../generated/cronos/data";
import {SidenavService} from "./sidenav.service";
import {Router} from "@angular/router";
import {MediaService} from "../../media.service";

@Component({
  selector: 'account-sidenav',
  templateUrl: './account-sidenav.component.html',
  styleUrls: ['./account-sidenav.component.scss']
})
export class AccountSidenavComponent implements OnInit {

  @Input() collapsed: boolean = true
  @Input() darkMode: boolean = true
  animationTrigger: boolean = false

  notifications: DisplayAccountNotificationData[] = []
  unseenNotifications: DisplayAccountNotificationData[] = []
  notificationPage: number = 0

  pageSize: number = 99

  showLoginMask: boolean = false

  info: AccountInfoData

  constructor(
    public authService: AuthService,
    private accountResource: AccountResource,
    private notificationResource: NotificationResource,
    public utilService: UtilsService,
    private sidenavService: SidenavService,
    public mediaService: MediaService,
    public router: Router
  ) {
    this.sidenavService.expandSidenav.subscribe(next => {
      if (next == true) this.collapsed = false
    })
  }

  ngOnInit(): void {
    if(this.authService.isAuthenticated()){
      this.loadNotifications()
      this.loadProfileData()
    }
    this.sidenavService.getProfilePicChange().subscribe(() => {
      this.loadProfileData()
    })

    this.router.events.subscribe(() => {
      this.collapsed = true
    });
  }

  loadProfileData(){
    if(!this.authService.isAuthenticated()){
      return
    }
    this.accountResource.getInfo().then(result=>{
      this.info = result
    })
  }

  loadNotifications(){
    this.notificationResource.getOwnNotifications({page: this.notificationPage, pageSize: this.pageSize}).then(result => {
      this.notifications = result.content
      this.unseenNotifications = this.notifications.filter(notification => !notification.seen)
    })
  }

  expandMenu(){
    this.collapsed = false
    if(this.mediaService.isMobile()){
      this.utilService.reportDialogChange(true)
    }
  }

  collapseMenu(){
    this.collapsed = true
    if(this.mediaService.isMobile()){
      this.utilService.reportDialogChange(false)
    }
  }
}
