import {Component, OnInit} from '@angular/core';
import '@googlemaps/extended-component-library/api_loader.js';
import '@googlemaps/extended-component-library/place_building_blocks/place_data_provider.js';
import '@googlemaps/extended-component-library/place_building_blocks/place_reviews.js';

@Component({
  selector: 'app-studyscript-google',
  templateUrl: './studyscript-google.component.html',
  styleUrls: ['./studyscript-google.component.scss']
})
export class StudyscriptGoogleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
