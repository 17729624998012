<div class="dateItem" fxLayout="row" fxLayoutAlign="space-between center" [ngClass]="{'hide-border': hideBorder}">
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start center" style="white-space: nowrap;">
      <i fxHide.xs class="fa fam fa-calendar-alt"></i>
      {{seminar.date | date : 'EEE, dd.MM.yyyy'}}
    </div>
    <div fxLayout="row" fxLayoutAlign="start center"><i fxHide.xs
                                                        class="far fam fa-clock"></i>
      {{seminar.date | date : 'HH:mm'}}
      -
      {{seminar.endTime | date : 'HH:mm'}}
    </div>
    <div fxHide fxShow.xs style="color: #777;font-style: italic;font-size: 12px">
            <span *ngIf="seminar.type === 'OfflineSeminarDate'"><i
              class="fa fam fa-map-marker-alt"></i>Vor Ort</span>
      <span *ngIf="seminar.type.indexOf('WebinarDate') > -1"> <i
        class="fas fa-globe fam"></i>Online-Termin</span>
    </div>
  </div>
  <div class="isu-padding-2x" fxHide.xs style="color: #777;">
          <span *ngIf="seminar.type === 'OfflineSeminarDate'"><i
            class="fa fam fa-map-marker-alt"></i>{{seminar.address}}</span>

    <span *ngIf="seminar.type.indexOf('WebinarDate') > -1"><i
      class="fas fa-globe fam"></i>Online-Termin</span>
  </div>
  <div fxLayout="column" fxLayoutAlign="center center">
    <button class="hid-button secondary outline expand"
            [class.outline]="selectedSeminarId && selectedSeminarId != seminar.id"
            (click)="selectSeminar(seminar)">
      {{getButtonText(seminar)}}
    </button>
    <span class="freeSlots" *ngIf="seminar.freeSlots == 1">{{seminar.freeSlots}} Platz frei</span>
    <span class="freeSlots" *ngIf="seminar.freeSlots != 1">{{seminar.freeSlots}} Plätze frei</span>
  </div>
</div>
