import {NgModule} from '@angular/core';
import {RouterLink, RouterLinkActive, RouterModule, Routes} from "@angular/router";
import {FeedbackOverviewComponent} from "./feedback-overview.component";
import {CommonModule} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {HidInteractionsModule} from "../hid-interactions/hid-interactions.module";
import {FeedbackStarsModule} from "../feedback-stars/feedback-stars.module";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FeedbackSelectorComponent} from "./components/feedback-selector/feedback-selector.component";
import {FeedbackPageHeadingPipe, SeminarFeedbackListHeader, SeminarHeaderPipe} from "./utils/feedback-pipes.pipe";
import {NgxJsonLdModule} from "ngx-json-ld";


const appRoutes: Routes = [
  {
    path: 'feedbacks/:selection', component: FeedbackOverviewComponent,
  }];

@NgModule({
  declarations: [
    FeedbackOverviewComponent,
    FeedbackSelectorComponent,
    SeminarHeaderPipe,
    FeedbackPageHeadingPipe,
    SeminarFeedbackListHeader
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(appRoutes),
    BrowserModule,
    FlexModule,
    HidInteractionsModule,
    FeedbackStarsModule,
    MatCheckboxModule,
    RouterLink,
    RouterLinkActive,
    FlexLayoutModule,
    NgxJsonLdModule,
  ]
})
export class FeedbackOverviewModule { }
