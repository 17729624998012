import {FeedBackOverviewData} from "../../generated/cronos/data";
import {SeminarIdentifier} from "./feedback-selector.service";

export class FormatFeedbackGroup{

  data: FeedBackOverviewData;

  constructor(
    public identifier:SeminarIdentifier,
    public formatIds: number[]
  ) {}

  getCompactCount():string{
    if (!this.data) return
    let sum = this.data.totalRatingCount
    if(sum < 1e3) return sum.toString()
    return (sum / 1e3).toFixed(0)+"K+"
  }
}
