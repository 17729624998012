import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PublicSeminarData} from "../../generated/cronos/data";

@Component({
  selector: 'app-single-seminar-date',
  templateUrl: './single-seminar-date.component.html',
  styleUrls: ['./single-seminar-date.component.scss']
})
export class SingleSeminarDateComponent implements OnInit {

  @Input() seminar: PublicSeminarData
  @Input() hideBorder: boolean = false
  @Output() selectedSeminar: EventEmitter<PublicSeminarData> = new EventEmitter<PublicSeminarData>()

  buttonText: string = "Auswählen"
  selectedSeminarId: number
  constructor() { }

  ngOnInit(): void {
  }

  selectSeminar(seminar: PublicSeminarData) {
    this.selectedSeminar.next(seminar)
  }

  getButtonText(promotion: PublicSeminarData): string {
    if (promotion.id == this.selectedSeminarId) {
      return 'Ausgewählt';
    } else {
      return this.buttonText;
    }
  }

}
