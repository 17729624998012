import {Component, Input, OnInit} from '@angular/core';
import {PublicSeminarData} from '../../generated/cronos/data';
import {dateFromISO8601} from '../../utils.service';
import {Subject} from 'rxjs';

interface SeminarWeekItem {
  dates: PublicSeminarData[],
  weeksFromNow: number
}

interface WeekScope {
  index: number,
  startDate: Date,
  endDate: Date
}


@Component({
  selector: 'app-checkout-seminardate-segmentation',
  templateUrl: './checkout-seminardate-segmentation.component.html',
  styleUrls: ['./checkout-seminardate-segmentation.component.scss']
})
export class CheckoutSeminardateSegmentationComponent implements OnInit {

  @Input() seminarDates: PublicSeminarData[] = [];
  @Input() triggerFunction: () => void;
  @Input() selectedDateSubject: Subject<PublicSeminarData>;
  @Input() displayedSeminarAmountSubject: Subject<number>;
  @Input() initialSelectedSeminar: PublicSeminarData;
  buttonText = 'Auswählen';
  segmentedDates: SeminarWeekItem[] = [];
  totalDisplayedDates: number = 0;
  //amount of displayed weeks - 1
  weekScope = 6;
  selectedPromotionId: number;

  constructor() {
  }

  ngOnInit(): void {
    this.prepareDates();
    if (this.initialSelectedSeminar) {
      //manually set because we don't want to trigger the subject here
      this.selectedPromotionId = this.initialSelectedSeminar.id;
    }
  }


  prepareDates() {
    // this.loading = false;
    this.segmentDates();
    for (let date of this.seminarDates) {
      date.freeSlots = this.calculateRemainingSlots(date.freeSlots);
    }
    //recalculate number of shown seminars to make sure only shown seminars are pushed into data layer
    this.segmentedDates.forEach(x => this.totalDisplayedDates = this.totalDisplayedDates + x.dates.length);
    // this.reportReached(false) //only loading dates / coming here if we didnt skip

  }


  calculateRemainingSlots(slots) {
    if (slots < 4) {
      return slots;
    }
    return Math.max(0, Math.ceil(slots * (3 / 8 + this.nicoCoefficient(slots))));
  }

  nicoCoefficient = places => (places < 4 || places > 10) ? 0 : Math.ceil((places - 2) / 2) / 8;


  segmentDates() {
    let weeks: WeekScope[] = [];
    const today = new Date();
    const day = new Date().getDay() || 7;
    let firstMonday = new Date();
    firstMonday.setHours(-24 * (day - 1));
    firstMonday.setMinutes(0, 0);

    let currentWeek = 0;
    while (currentWeek < this.weekScope) {
      let startDate = new Date(firstMonday.getTime());
      startDate.setDate(startDate.getDate() + (currentWeek * 7));
      let endDate = new Date(startDate.getTime());
      endDate.setDate(startDate.getDate() + 7);
      weeks.push({index: currentWeek, startDate: startDate, endDate: endDate});
      currentWeek++;
    }

    for (let week of weeks) {
      var res: PublicSeminarData[] = [];
      for (let date of this.seminarDates) {

        if (dateFromISO8601(date.date).getTime() > week.startDate.getTime() && dateFromISO8601(date.date).getTime() < week.endDate.getTime()) {
          res.push(date);
        }
      }
      this.segmentedDates.push({weeksFromNow: week.index, dates: res});
    }

    //If no weekitem contains any dates, we offer waitinglist signup
    let totalDatesToDisplay = 0;

    for (let item of this.segmentedDates) {
      totalDatesToDisplay += item.dates.length;
    }

    this.displayedSeminarAmountSubject?.next(totalDatesToDisplay);
  }

  selectPromotion(promotion: PublicSeminarData) {
    if (this.selectedPromotionId && this.selectedPromotionId == promotion.id) {
      this.selectedPromotionId = undefined;
    } else {
      this.selectedPromotionId = promotion.id;
    }
    this.selectedDateSubject.next(promotion)
  }

  getButtonText(promotion: PublicSeminarData): string {
    if (promotion.id == this.selectedPromotionId) {
      return 'Ausgewählt';
    } else {
      return this.buttonText;
    }
  }
}
