import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FeedbackSelectorService, SeminarIdentifier, SeminarUrlIdentifier} from "./utils/feedback-selector.service";
import {MetaService} from "../meta.service";
import {FeedbackDisplayData} from "../generated/cronos/data";
import {PromotionApiResourceV2} from "../generated/cronos/resources";
import {MediaService} from "../inno-utils/media.service";
import {Subscription} from "rxjs";


@Component({
  selector: 'app-feedback-overview',
  templateUrl: './feedback-overview.component.html',
  styleUrls: ['./feedback-overview.component.scss']
})
export class FeedbackOverviewComponent implements OnInit, OnDestroy {

  feedback: FeedbackDisplayData[] = [];
  initialized: boolean = false;
  feedbackLoading: boolean = false;
  pageSize: number = 10;
  page: number = 0;
  totalElements: number;
  filterForComments: boolean = true;
  seminarIdentity : number

  loadingSkeletonArray = new Array<number>(this.pageSize)

  ratingJsonLd = null
  formatToFeedbackDataSubscription: Subscription

  constructor(
    private route:ActivatedRoute,
    private metaService: MetaService,
    private promotionApiResourceV2: PromotionApiResourceV2,
    public selectorService: FeedbackSelectorService,
    public mediaService: MediaService,
  ) {
    this.formatToFeedbackDataSubscription = this.selectorService.seminarFeedbackDataSubject.subscribe(next => {
      if (next && this.seminarIdentity != null) this.getRatingJsonLD()
    })
  }

  ngOnInit(): void {
    this.route.url.subscribe( url =>{
      url.length > 1 ? this.determineSeminarIdentity(url[1].path) : this.seminarIdentity = SeminarIdentifier.All
      this.page = 0
      this.pageSize = 10
      this.loadFeedbackDataPage(this.page)

      if (this.selectorService.seminarFeedbackDataSubject.value != null) this.getRatingJsonLD()
      }
    )

    this.metaService.setMetaTag("description", "Was denken Studenten über die Hochschulinitiative? Das Seminar-Feedback zu Steuer, Excel, Thesis & Co. findest du hier!")
    this.metaService.setCustomTitleTag("Erfahrungen zu den Workshops | Hochschulinitiative Deutschland")
  }

  ngOnDestroy() {
    this.formatToFeedbackDataSubscription?.unsubscribe()
  }

  determineSeminarIdentity(urlSegment: String){
    switch (urlSegment){
      case SeminarUrlIdentifier.Steuer:
        this.seminarIdentity = SeminarIdentifier.Steuer
        return
      case SeminarUrlIdentifier.Thesis:
        this.seminarIdentity = SeminarIdentifier.Thesis
        return
      case SeminarUrlIdentifier.Excel:
        this.seminarIdentity = SeminarIdentifier.Excel
        return
      case SeminarUrlIdentifier.Finance:
        this.seminarIdentity = SeminarIdentifier.Finance
        return
      case SeminarUrlIdentifier.Fin4Women:
        this.seminarIdentity = SeminarIdentifier.Fin4Women
        return
    }
  }

  loadFeedbackDataPage(page: number) {
    this.feedbackLoading = true;

    this.feedback = []
    this.page = page

    this.promotionApiResourceV2.getRatingsByPromotionType({
      formatIds: this.selectorService.formatToIds[this.seminarIdentity].ids,
      filterForCommentExists: this.filterForComments,
      page: this.page,
      pageSize: this.pageSize
    }).then(
      result => {
        result.content.forEach(value => {
          this.feedback.push(value)
        })
        this.pageSize += this.pageSize
        this.loadingSkeletonArray.length = this.pageSize
        this.totalElements = result.totalElements;
        this.feedbackLoading = false;
        this.initialized = true;
      }
    )
  }

  getRatingJsonLD() {
    let formatFeedback = this.selectorService.seminarFeedbackData[this.seminarIdentity]
    let formatData = this.selectorService.formatPageLDInfoData[this.seminarIdentity]
    let url = "/angebote/" + formatData.url
    this.ratingJsonLd = {
      '@context': 'http://schema.org',
      '@type': 'AggregateRating',
      url: window.location.href,
      name: formatData.titleTag,
      itemReviewed: {
        '@type': "Product",
        name: formatData.titleTag,
        ...(formatData.url ? { url: window.location.origin + url } : {}),
        aggregateRating: {
          ratingValue: formatFeedback.feedbackData.averageRating,
          bestRating: 5,
          ratingCount: formatFeedback.feedbackData.totalRatingCount,
          worstRating: 1
        },
      },
      ratingValue: formatFeedback.feedbackData.averageRating,
      bestRating: 5,
      ratingCount: formatFeedback.feedbackData.totalRatingCount,
      worstRating: 1
    }

  }

  filterFeedbacksForComments(filter: boolean){
    this.filterForComments = filter
    this.loadFeedbackDataPage(0)
  }

  protected readonly Array = Array;
}
