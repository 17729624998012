<div class="login-menu-wrapper" fxLayout="column" fxLayoutAlign="center center">
  <div (click)="$event.stopPropagation();$event.preventDefault();">

    <div *ngIf="token == null">
      <input class="login-input" placeholder="Email" [formControl]="email" type="email">
      <button class="signup-button" (click)="request()" [disabled]="email.invalid || mailRequested">Anfragen</button>

      <div *ngIf="mailRequested" class="hint-text">In Kürze solltest du eine E-Mail zum Zurücksetzten deines Passworts erhalten</div>
    </div>

    <div *ngIf="token != null">
      <hid-loading-spinner [loadingBool]="validatingToken">
        <div *ngIf="tokenConfirmed">
          <input class="login-input"  placeholder="Passwort" [formControl]="password" type="password"/>
          <button class="signup-button" (click)="reset()">Zurücksetzen</button>
        </div>
        <div *ngIf="tokenConfirmed == false" >
          Dein Passwort-Zurücksetzen Link ist leider abgelaufen.
        </div>
      </hid-loading-spinner>

    </div>
  </div>
</div>


<!--
{{token}}
-->
