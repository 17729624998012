import {Component, Input, OnInit} from '@angular/core';
import {FormatFeedbackGroup} from "../../utils/format-feedback-group";
import {PromotionApiResourceV2} from "../../../generated/cronos/resources";
import {Router} from "@angular/router";
import {FeedbackSelectorService, SeminarIdentifier, SeminarUrlIdentifier} from "../../utils/feedback-selector.service";
import {MediaService} from "../../../inno-utils/media.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-feedback-selector',
  templateUrl: './feedback-selector.component.html',
  styleUrls: ['./feedback-selector.component.scss', '../../feedback-overview.component.scss']
})
export class FeedbackSelectorComponent implements OnInit {


  feedbackGroups: FormatFeedbackGroup[] = [
    new FormatFeedbackGroup(SeminarIdentifier.All, this.selectorService.formatToIds[SeminarIdentifier.All].ids),
    new FormatFeedbackGroup(SeminarIdentifier.Steuer, this.selectorService.formatToIds[SeminarIdentifier.Steuer].ids),
    new FormatFeedbackGroup(SeminarIdentifier.Thesis, this.selectorService.formatToIds[SeminarIdentifier.Thesis].ids),
    new FormatFeedbackGroup(SeminarIdentifier.Excel, this.selectorService.formatToIds[SeminarIdentifier.Excel].ids),
    new FormatFeedbackGroup(SeminarIdentifier.Finance, this.selectorService.formatToIds[SeminarIdentifier.Finance].ids),
    new FormatFeedbackGroup(SeminarIdentifier.Fin4Women, this.selectorService.formatToIds[SeminarIdentifier.Fin4Women].ids)
  ];

  @Input()
  selectedSeminar: SeminarIdentifier;

  initialized:boolean = false

  //creating an empty array to iterate over when instantiating the loading skeleton.
  //one less in length than the feedback groups, bc the last feedback bubble has special width and needs its own instantiation
  loadingSkeletonArray = new Array<number>(this.feedbackGroups.length-1)

  formatToFeedbackDataSubscription: Subscription

  constructor(public promotionApiResourceV2: PromotionApiResourceV2,
              public router: Router,
              public selectorService: FeedbackSelectorService,
              public mediaService: MediaService) {
    this.formatToFeedbackDataSubscription = this.selectorService.seminarFeedbackDataSubject.subscribe(next => {
      if (next) this.addFeedbackDataToGroups(next)
    })
  }

  ngOnInit(): void {
  }

  getGroup(identifier: SeminarIdentifier):FormatFeedbackGroup{
    return this.feedbackGroups.find(e => e.identifier == identifier)
  }

  addFeedbackDataToGroups(data: any) {
    this.feedbackGroups.forEach(group => {
      group.data = data[group.identifier].feedbackData
      group.data.averageRating = parseFloat(group.data.averageRating.toFixed(1))
    })

    this.initialized = true
  }

  protected readonly SeminarIdentifiers = SeminarIdentifier;
    protected readonly Array = Array;
  protected readonly SeminarUrlIdentifier = SeminarUrlIdentifier;
}
