<div class="landing-page-wrapper">

  <section class="header">
    <div class="header-content">
      <h1>Hochwertiger Druck für deine
        Skripte & Abschlussarbeiten<span *ngIf="mediaService.isMobile()">🖨️</span></h1>
      <div class="header-subtitle">Du studierst, wir drucken - mit Studyscript entspannt zum Abschluss</div>
      <a class="cta-button" (click)="openSelectPrintDialog()">Jetzt drucken</a>
    </div>

    <img *ngIf="!mediaService.getMaxWidthQuery(1080).matches" src="assets/img/studyscript/studyscript-header.png" alt="">
  </section>

  <section class="rating-banner" [ngClass]="{'rating-banner-shown': studyScriptRatingCount > 0}">
    <div>
      <span *ngIf="!mediaService.getMaxWidthQuery(600).matches">
        <b>{{studyScriptAverageRating}} / 5</b> ⭐️ basierend auf <b>{{studyScriptRatingCount}}</b> Google Bewertungen
      </span>
      <span *ngIf="mediaService.getMaxWidthQuery(600).matches">
        <b>{{studyScriptAverageRating}} / 5</b> ⭐️ auf Google
      </span>
    </div>

    <div>
      |
    </div>


    <div class="icon-animate">
      <a (click)="scrollToReviews()">zu den Bewertungen <i class="mdi mdi-arrow-down"></i></a>
    </div>
  </section>


  <section class="usp-section">
    <div class="usp-container">
      <div class="emoji">🏆</div>
      <div class="usp-content">
        <b>Bessere Qualität</b> als in <br>
        jedem Copyshop
      </div>
    </div>

    <div class="usp-container">
      <div class="emoji">🖨️</div>
      <div class="usp-content">
        <b>exklusive Druckvorschau</b> <br>
        im Online Konfigurator
      </div>
    </div>

    <div class="usp-container">
      <div class="emoji">🌿</div>
      <div class="usp-content">
        <b>Nachhaltiger</b> <br>
        Druck aller Produkte
      </div>
    </div>

    <div class="usp-container">
      <div class="emoji">📦</div>
      <div class="usp-content">
        Die Deadline naht? <br>
        <b>Next Day Delivery</b> möglich
      </div>
    </div>
  </section>


  <section class="product-section">
    <label class="switch">
      <input type="checkbox" [checked]="productIndex" (change)="onToggleChange($event)">
      <span class="slider"></span>
      <span class="labels">
                <span class="slider-text-1">Abschlussarbeiten</span>
                <span class="slider-text-2">Skripte</span>
            </span>
    </label>


    <div class="product-container">
      <h2 [innerHTML]="productContentData[productIndex].heading"></h2>
      <div class="product-select">
        <ngx-gallery [options]="galleryOptions" (imagesReady)="gallery.show(0)" [images]="productContentData[productIndex].images" #gallery></ngx-gallery>

        <div class="product-info">
          <div class="product-info-price">
            <b>Ab {{ productContentData[productIndex].price }}€</b>
            <mat-divider *ngIf="!mediaService.getMaxWidthQuery(950).matches"></mat-divider>
            <div class="vertical-divider"></div>
            <b>{{ productContentData[productIndex].shipping }}</b>
          </div>
          <mat-divider *ngIf="!mediaService.getMaxWidthQuery(950).matches"></mat-divider>
          <b>{{ productContentData[productIndex].usp }}</b>
          <mat-divider *ngIf="!mediaService.getMaxWidthQuery(950).matches"></mat-divider>
          <p>{{ productContentData[productIndex].details }}</p>
          <div class="sub-detail-text" *ngIf="productContentData[productIndex].subDetail">
            {{ productContentData[productIndex].subDetail }}
          </div>
        </div>
      </div>
      <a class="cta-button" routerLink="konfigurator" [fragment]="productContentData[productIndex].type" (click)="reportStudyscriptTrackingEvent('ProductInfo')">
        {{ productContentData[productIndex].buttonText }}
      </a>
    </div>
  </section>

  <section class="info-section">
    <img src="assets/img/studyscript/printer.png" alt="">
    <h2>Studyscript x Hochschulinitiative</h2>
    <p *ngIf="!mediaService.isMobile()">
      Studyscript ist dein Partner, wenn es um’s <b>Drucken im Studium</b> geht.
      Gemeinsam sorgen wir dafür, dass dein <b>Studium erfolgreicher</b> und dein <b>Unialltag entspannter</b>
      wird – ob durch
      professionell gedruckte und gebundene Abschlussarbeiten oder Skripte, die deinen <b>Lernerfolg steigern.</b>
    </p>
    <p *ngIf="!mediaService.isMobile()">
      Das Beste? Studyscript übernimmt die komplette Abwicklung deiner Bestellung und liefert dir deine
      Abschlussarbeit auf Wunsch auch per Express, wenn die Deadline drängt.
    </p>

    <p *ngIf="mediaService.isMobile()">
      Studyscript ist dein Partner, wenn es ums <b>Drucken im Studium</b> geht. Zusammen machen wir deinen <b>Unialltag entspannter & dein
      Studium erfolgreicher.</b>
      Das Beste? Studyscript selbst übernimmt die komplette Abwicklung deiner Bestellung
    </p>
  </section>

  <section class="video-section">
    <div class="video-wrapper">
      <div class="video-container">

        <div style="position:relative;"
             [ngStyle]="{'padding': mediaService.getMaxWidthQuery(800).matches ? '125% 0 0 0' : '56.25% 0 0 0'}">
          <iframe id="video" #video src="https://player.vimeo.com/video/1069998871?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&loop=1&muted=1&controls=1"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                  style="position:absolute;top:0;left:0;width:100%;height:100%;"
                  title="250327__Studyscript_stressige-abgabe_16zu9_blur"></iframe>
        </div>
      </div>

      <img src="assets/img/studyscript/shine-doodle.png" alt="" class="doodle-left">
      <img src="assets/img/studyscript/shine-doodle.png" alt="" class="doodle-right">
    </div>



  </section>

<!--  <section class="sustainable-section">-->
<!--    <div class="sustainable-content-wrapper">-->
<!--      <div class="sustainable-content">-->
<!--        <h2>Nachhaltigkeit <span *ngIf="mediaService.isMobile()">🌱</span></h2>-->
<!--        <p>-->
<!--          Um dem Klimawandel entgegenzuwirken, sind alle Produkte von Studyscript <b>klimapositiv.</b>-->
<!--        </p>-->
<!--        <p>-->
<!--          Zusätzlich zur CO₂-neutralen Produktion und CO₂-neutralem Arbeiten wird mit jedem bestellten Produkt CO₂-->
<!--          aus der Atmosphäre geholt.-->
<!--          Sie verwenden zudem ausschließlich <b>FSC-zertifiziertes Papier</b> aus nachhaltiger Forstwirtschaft.-->
<!--        </p>-->
<!--      </div>-->
<!--      <img *ngIf="!mediaService.isMobile()" src="assets/img/studyscript/tree.png" alt="">-->
<!--    </div>-->
<!--  </section>-->

  <section class="reviews-section" #reviewsSection>
    <div class="reviews-content">
      <div class="width-100">
        <h2>Das sagen Studis 👉</h2>
      </div>
      <app-studyscript-google></app-studyscript-google>
    </div>
  </section>

</div>
