import {Component, Input, OnInit} from '@angular/core';
import {MinimalPromotionData} from "../../../generated/cronos/data";

@Component({
  selector: 'app-promotion-info-box',
  templateUrl: './promotion-info-box.component.html',
  styleUrls: ['./promotion-info-box.component.scss']
})
export class PromotionInfoBoxComponent implements OnInit {

  @Input()
  promotionData: MinimalPromotionData

  @Input() hideWebinarDateText: boolean = false

  constructor() { }

  ngOnInit(): void {
  }

}
