import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {buildContainerImageUrl, delay, UtilsService} from '../../utils.service';
import {LocalStorageService} from '../../local-storage.service';
import {MediaService} from '../../media.service';
import {BehaviorSubject} from 'rxjs';
import {ShareService} from '../../share/share.service';
import {TrackingService} from '../../tracking.service';
import {DomSanitizer, Meta, Title} from '@angular/platform-browser';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  ContactSupportModalComponent
} from '../../contact-support-modal/contact-support-modal/contact-support-modal.component';
import {PublicFormatPageData} from '../../generated/cms/data';
import {PromotionApiResourceV2, PublicStructureResourceV2} from '../../generated/cronos/resources';
import {MetaService} from '../../meta.service';
import Bugsnag from '@bugsnag/js';
import {ViewportScroller} from '@angular/common';
import {FormControl, Validators} from '@angular/forms';
import {PublicOfferPageResource} from '../../generated/cms/resources';
import {AnalyticsService} from '../../analytics/analytics.service';
import {GlobalCheckoutService} from '../../checkout/global-checkout.service';
import {SteuerSchlittschuhComponent} from '../static-components/steuer-schlittschuh/steuer-schlittschuh.component';
import {
  OfferPageWebinarDatesComponent
} from "../static-components/offer-page-webinar-dates/offer-page-webinar-dates.component";

@Component({
  selector: 'app-offer-page',
  templateUrl: './offer-page.component.html',
  styleUrls: ['./offer-page.component.scss']
})
//TODO rename to formatpagecomponent
export class OfferPageComponent implements OnInit, AfterViewChecked, OnDestroy, AfterViewInit {

  componentPlaceholdersReady = new BehaviorSubject<boolean>(false);
  private componentMappings: { [key: string]: any } = {
    'steuer-schlittschuh': SteuerSchlittschuhComponent,
    'seminar-dates': OfferPageWebinarDatesComponent
  }

  @ViewChild("offerImage", {static: true}) imageWrapper: ElementRef;
  @ViewChild("offerPageWrapper") offerPageWrapper: ElementRef;
  @ViewChild("mobileCta") mobileCta: ElementRef

  routingUrl;

  page: PublicFormatPageData = <PublicFormatPageData>{
    content: ''
  };

  instantCheckout: boolean = false;

  forcedSplitId: number;
  loading = new BehaviorSubject<boolean>(true);
  imageSrc = '';

  ratingLD;

  trackingClasses: string = '';

  countdownInterval: any = null;

  rating;
  showRatings = false;
  urlParams;
  completeRedirectUrl: string = '';

  showSidebar: boolean = true;

  showMobileCta: boolean = true
  vanishMobileCta: boolean = false

  altText: string = "Alternativ-Text für Angebotsbild"

  isTalentAgentOffer: boolean = false

  lastScrollTop: number = 0
  scrollThreshold: number = 75
  hideMobileCta: boolean
  ctaFixed: boolean


  constructor(private offerPageResource: PublicOfferPageResource,
              private localStorageService: LocalStorageService,
              private route: ActivatedRoute,
              private mediaService: MediaService,
              private promotionApiResourceV2: PromotionApiResourceV2,
              @Inject("env") private env,
              private router: Router,
              public trackingService: TrackingService,
              private shareService: ShareService,
              private modal: NgbModal,
              private meta: Meta,
              private elem: ElementRef,
              private titleService: Title,
              private metaService: MetaService,
              private utilsService: UtilsService,
              private sanitizer: DomSanitizer,
              public viewportScroller: ViewportScroller,
              private renderer: Renderer2,
              private viewContainerRef: ViewContainerRef,
              private cronosStructureApi: PublicStructureResourceV2,
              public analyticsService: AnalyticsService,
              private globalCheckoutService: GlobalCheckoutService
  ) {

    // this.checkoutService.initLocalSplit(this.route.snapshot.queryParams.lsa)

    route.params.subscribe(params => {
      this.routingUrl = params.routingUrl;
      this.loading.next(true)
      this.subbedCustomCtaEvents = false

      //TODO add cleaner version to hide mobile cta for offer pages
      if (this.routingUrl.includes('dealwunder')) {
        this.showMobileCta = false
      }

      route.queryParams
        .subscribe(queryParams => {
          this.urlParams = queryParams;
          if(this.checkForPreviewAndLoad(queryParams["preview"])) return

          let referralCode = queryParams["referral"];
          if (referralCode) {
            this.globalCheckoutService.setReferralCode(referralCode)
          }

          this.instantCheckout = queryParams.instantCheckout == 'true';
          this.forcedSplitId = queryParams.spid;
          this.loadPage()
        })
    });

    //If user did arrive here without any params, bring up the last known. This is a workaround because analytics is retarded and would have lost the last click
    if (!route.snapshot.params['utm_source'] && !route.snapshot.params['utm_campaign'] && !route.snapshot.params['utm_medium'] && !route.snapshot.params['utm_content']) {
      route.queryParams['test'] = "12";
    }

    if (router.url.includes('karriereplattform') || router.url.includes('jobs-plattform')) {
      this.isTalentAgentOffer = true
    } else {
      this.isTalentAgentOffer = false
    }
  }

  quizAnswer = new FormControl("", [Validators.minLength(1), Validators.required])

  subbedCustomCtaEvents = false;


  ngOnInit() {
    this.setUpPageContentCta();
  }

  ngAfterViewInit() {
    this.componentPlaceholdersReady.subscribe(ready => {
      if (ready) {
        console.log('ready to insert components')
        this.insertComponentsFromPlaceholders();
        this.loading.next(false)
      }
    })
  }

  preparePageHtmlReplacements(pageContent: string): string {
    for (let placeholder in this.componentMappings) {
      const regex = new RegExp(`<!--insert:${placeholder}-->`, 'g');
      pageContent = pageContent.replace(regex, `<span class="placeholder-identifier skelett-placeholder" data-component="${placeholder}"></span>`);
    }
    return pageContent;
  }

  insertComponentsFromPlaceholders() {
    const placeholders = this.elem.nativeElement.querySelectorAll('.placeholder-identifier');
    placeholders.forEach((placeholder: HTMLElement) => {
      console.log('placeholder found')
      const componentName = placeholder.getAttribute('data-component');
      // Check if componentName exists and is a key in componentMappings
      if (componentName && this.componentMappings.hasOwnProperty(componentName)) {
          console.log('inserting component ' + componentName)
        const componentClass = this.componentMappings[componentName!];

        this.insertComponent(placeholder, componentClass)
      }
    });
  }

  insertComponent(placeholder: HTMLElement, component: any) {
      // Create the component only after data is loaded
      const componentRef = this.viewContainerRef.createComponent(component);

      // Insert the component into the DOM and remove the placeholder
      this.renderer.insertBefore(
        placeholder.parentElement,
        componentRef.location.nativeElement,
        placeholder
      );
      placeholder.remove();
  }

  checkForPreviewAndLoad(previewId: string){
    if (previewId == null)  return false;
    this.offerPageResource.getFormatSplitPreview(previewId).then(
          result =>  {
            result.content = this.preparePageHtmlReplacements(result.content)
            this.page = result;

            this.showSidebar = result.showSidebar;
            // built offerImage for preview
            this.localStorageService.updateCurrentPage(this.page)
            this.imageSrc = buildContainerImageUrl(this.imageWrapper, this.page.imageContainerId);
            this.delayAndInjectMarketingModules()
          }
      );
      return true
  }

  scanForAndInjectShareModul() {
    let shareModuls = document.getElementsByClassName('util-share-modul')
    for (let i = 0; i < shareModuls.length; i++) {
      shareModuls[i].innerHTML = '<button class="hid-button secondary inverse">Angebot teilen <i class="fa fa-share-alt"></i></button>'
      shareModuls[i].addEventListener('click', () => this.initShare())
    }
  }

  removeTrackingFromMobileCta(){
    if(!this.mediaService.isMobile()){
      return
    }
    let mobileCta = document.getElementById('mobile-cta-button')
    for (let i = mobileCta.classList.length - 1; i >= 0; i--) {
      const className = mobileCta.classList[i];
      if (className.startsWith("ita")) {
        mobileCta.classList.remove(className);
      }
    }
  }


  //Marketing can give elements in their custom HTML the class page-content-cta that initiates the checkout
  setUpPageContentCta() {
    this.loading
      .subscribe(loading => {
        if (!loading && !this.subbedCustomCtaEvents) {
          this.listenToThesisAccordionElements()
          this.hideAndShowGermanOrNonGermanContent()
          let ctaButtons = this.elem.nativeElement.querySelectorAll('.page-content-cta')
          ctaButtons.forEach(e => {
            e.classList.add("ita_initiate-checkout");
            if (this.page.cmsOfferPageType === 'RedirectOfferPage') {
              this.prepareRedirectLink()
              e.href = this.completeRedirectUrl
            } else {
              e.addEventListener('click', this.initiateCheckout.bind(this))
            }
          });

          this.assignButtonIdsToCtas(Array.from(ctaButtons) as HTMLElement[])
          this.subbedCustomCtaEvents = true;

        }
      })
  }

  openContactDialog() {
    const modalref = this.modal.open(ContactSupportModalComponent)
  }

  ngAfterViewChecked() {
    if (this.router.url.includes("unser-adventskalender-2019") && document.getElementById("days") && !this.countdownInterval) {
      this.initializeCountdownScript();
    }

  }

  ngOnDestroy() {
    clearInterval(this.countdownInterval);
    this.metaService.resetRobotsTag();
    this.loading.unsubscribe();
  }

  //gives all cta buttons a data-buttonId to identify a clicked button
  assignButtonIdsToCtas(elements: HTMLElement[]) {
    let visibleButtonId: number = 0
    let hiddenButtonId: number = 0

    const setButtonId = (element: HTMLElement, isVisible: boolean, id: number) => {
      element.setAttribute("data-buttonId", id == 0 ? "header" : `in-text-${id}`);
    };

    elements.forEach(e => {
      if (window.getComputedStyle(e).display != "none" && e.offsetHeight > 0 && e.offsetWidth > 0) {
        setButtonId(e, true, visibleButtonId)
        visibleButtonId++
      } else {
        setButtonId(e, false, hiddenButtonId)
        hiddenButtonId++
      }
    })
  }

  setRatingDivContent() {
    let name = this.page.title
    if (this.page.titleTag) name = this.page.titleTag
    this.ratingLD = {
      '@context': 'http://schema.org',
      '@type': 'AggregateRating',
      url: window.location.href,
      name: name,
      itemReviewed: {
        '@type': "Product",
        name: this.page.title,
        aggregateRating: {
          ratingValue: this.page.averageFeedbackRating,
          bestRating: 5,
          ratingCount: this.page.feedbackCount,
          worstRating: 1
        },
      },
      ratingValue: this.page.averageFeedbackRating,
      bestRating: 5,
      ratingCount: this.page.feedbackCount,
      worstRating: 1
    }

  }

  loadPage() {
    //RoutingUrl equals null when redirected from a preview
    if (this.routingUrl === "null") return;
    if (this.routingUrl == this.page.routingUrl) {
      return;
    } //no need to refresh the page data if only queryParams have changed
    this.offerPageResource.getFormatOfferPage(this.routingUrl, {forcedSplitId: this.forcedSplitId, promId: this.urlParams['promId']})
      .then(res => {
        res.content = this.preparePageHtmlReplacements(res.content)
          this.page = res;

        /*
          NGClass in HTML is not working with current routing. This is the manual way to add the class
         */

        if (this.page.fullScreen) {
          this.offerPageWrapper.nativeElement.classList.add('fullscreen')
        } else if (this.offerPageWrapper.nativeElement.classList.contains('fullscreen')) {
          this.offerPageWrapper.nativeElement.classList.remove('fullscreen')
        }

        if (this.page.showCtaOnMobileScroll) {
          this.mobileCta.nativeElement.classList.add('hide-mobile-cta')
          window.addEventListener('scroll', this.onMobileScroll.bind(this))
          this.onMobileScroll()
        }

        this.analyticsService.reportOfferpageView(this.page)

          this.altText = res.altText
          this.showSidebar = res.showSidebar
          if (res.splitMode === 'InstantRedirect') {
            location.href = res.instantRedirectUrl
            return
          }

          //this.removeCtaMobileFooterForBirthdayRaffle()
          this.setRatingDivContent();
          this.rating = this.page.averageFeedbackRating


          // set html title
          if (this.page.titleTag !== null && this.page.titleTag !== '') {
            this.titleService.setTitle(this.page.titleTag);
          } else {
            this.metaService.resetTitleTag()
          }

          // set html meta title, description
          this.meta.removeTag('name ="title"');
          this.meta.removeTag('name ="description"');
          this.metaService.resetRobotsTag();
          this.metaService.setRobotsTag(this.page.indexPage);
          if (this.page.metaDescription != null) {
            if (this.meta.getTag("description") == null) {
              this.meta.addTag({name: 'description', content: this.page.metaDescription});
            } else {
              this.meta.updateTag({name: 'description', content: this.page.metaDescription});
            }
          } else {
            this.meta.updateTag({
              name: 'description', content: 'Die Hochschulinitiative Deutschland ist dein Partner im Studium:' +
                ' Wir bieten dir interessante Seminare, Vorlagen &' +
                ' tolle Blogartikel für dein erfolgreiches Studium!'
            });
          }
          // show recommended offers
          if (this.page.active == false) {
            this.router.navigate(['/angebote/weitere/' + this.page.pageId], {queryParamsHandling: 'preserve'}).then(() => {
            });
          }

          if (this.page) {
            let event = {formatType: this.page.formatType, splitId: this.page.selectedSplitId.toString()}
            if (this.page.cronosFormatId) {
              event['formatId'] = this.page.cronosFormatId
            }
          }

          if (this.urlParams['locationId']) {
            this.cronosStructureApi.getLocationById({value: this.urlParams['locationId']}).then(res => {
              this.globalCheckoutService.updateLocationOnUser(res)
            })
          }

          if (this.instantCheckout == true) {
            this.initiateCheckout(null);
          }
          if (this.page.cmsOfferPageType === 'RedirectOfferPage') {
            this.prepareRedirectLink()
          }

          this.localStorageService.updateCurrentPage(this.page)

          this.imageSrc = buildContainerImageUrl(this.imageWrapper, this.page.imageContainerId)

          this.delayAndInjectMarketingModules()
        }
      )
  }

  // workaround until we can decide in hid-admin whether to show the cta mobile footer or not.
  removeCtaMobileFooterForBirthdayRaffle() {

    if (this.page.cronosFormatId == 2314 || this.page.titleTag == "Uniwunder Birthday Bash") {
      this.showMobileCta = false
    }

  }

  injectTrackingFunctionsForRedirectPages() {
    let ctaList = document.getElementsByClassName('page-content-cta');
    for (let i = 0; i < ctaList.length; i++) {
      ctaList[i].addEventListener('click', (event) =>
        this.initiateCheckout(event));
    }
  }

  delayAndInjectMarketingModules() {
    //Async loading of html binding produces weird browser behaviour. Also if you dont give it a view MS, html is not bound yet at this point
    delay(100).then(() => {
      if (this.page.cmsOfferPageType === 'RedirectOfferPage') {
        this.injectTrackingFunctionsForRedirectPages()
      }
      window.scrollTo(0, 0);
      if (this.page.cronosFormatId == 2314 || this.page.titleTag == "Uniwunder Birthday Bash") {
        this.removeTrackingFromMobileCta()
      }
        this.scanForAndInjectShareModul()
        this.componentPlaceholdersReady.next(true);
    })
  }

  prepareRedirectLink() {
    this.completeRedirectUrl = this.page.redirectUrl + this.trackingService.generateUrlTailFromTracking(this.localStorageService.getLastSessionInfoUpdate(), this.page.redirectUrl)
    //this is a workaround to use the companyId tracking param for advertisement, todo: more general solution could be beneficial
    if (this.urlParams['companyId'] != null) {
      if (this.completeRedirectUrl.includes('?')) {
        this.completeRedirectUrl += '&companyId=' + this.urlParams['companyId']
      } else {
        this.completeRedirectUrl += '?companyId=' + this.urlParams['companyId']
      }
    }
  }

  initializeCountdownScript() {
    // Set the date we're counting down to
    let countDownDate = new Date("Dec 24, 2019 00:00:00").getTime();

    // Update the count down every 1 second
    this.countdownInterval = setInterval(function () {

      // Get today's date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Output the result in an element with id="demo"
      document.getElementById("days").innerHTML = days + "d";
      document.getElementById("hours").innerHTML = hours + "h";
      document.getElementById("minutes").innerHTML = minutes + "m";
      document.getElementById("seconds").innerHTML = seconds + "s";
      document.getElementById("days2").innerHTML = days + "d";
      document.getElementById("hours2").innerHTML = hours + "h";
      document.getElementById("minutes2").innerHTML = minutes + "m";
      document.getElementById("seconds2").innerHTML = seconds + "s";
    }, 1000);
  }

  initiateCheckout(event: Event | null) {
    this.globalCheckoutService.setCurrentPage(this.page);

    let buttonId = (event.target as HTMLElement).getAttribute("data-buttonId")

    if (this.page.cmsOfferPageType === 'RedirectOfferPage') {
      event.preventDefault();
      this.globalCheckoutService.reportTrackingEvent('InitiateCheckout', {buttonId: buttonId});
      window.location.href = this.completeRedirectUrl;
      return;
    } else {
      this.globalCheckoutService.reportTrackingEvent('InitiateCheckout', {buttonId: buttonId});
      try {

        let route = '/angebote/' + this.page.routingUrl + '/';
        switch (this.page.cronosFormatType) {
          case 'WebinarFormat':
            route += 'checkout/seminars/g';
            break;
          default:
            // id of the AecDisc format in cronos
            if (this.page.cronosFormatId == 2324) route += 'checkout/aec/g'
            else route += 'checkout/standard/g';
        }
        this.router.navigate([route], {queryParamsHandling: 'preserve'});

      } catch (e) {
        let releaseStage = 'production';
        if (!this.env.production) {
          releaseStage = 'development';
        }
        Bugsnag.notify(e);
      }
    }

  }

  initShare() {
    this.shareService.showSharingButtons('/angebote/' + this.page.routingUrl, 'offerpage-share', 'Hey, ich habe ein cooles Angebot von der Hochschulinitiative für dich: ', null);
  }

  onMobileScroll(): void {
    const st = window.scrollY;
    if (Math.abs(st - this.lastScrollTop) > this.scrollThreshold) {
      if (st < this.scrollThreshold) {
        this.mobileCta.nativeElement.classList.add('hide-mobile-cta')
      } else {
        this.mobileCta.nativeElement.classList.remove('hide-mobile-cta')
      }
      this.lastScrollTop = st;
    }
  }

  hideAndShowGermanOrNonGermanContent() {
    let germanContents = Array.from(document.getElementsByClassName("german-only"))
    let nonGermanOnly = Array.from(document.getElementsByClassName("non-german-only"))
    if (!navigator.language.startsWith("de")) {
      germanContents.forEach((content: HTMLElement) => content.style.display = "none")
      nonGermanOnly.forEach((content: HTMLElement) => content.style.display = "block")
    } else {
      nonGermanOnly.forEach((content: HTMLElement) => content.style.display = "none")
      germanContents.forEach((content: HTMLElement) => content.style.display = "block")
    }
  }

  // add or remove opened and closed class on accordion toggle to better see whether accordion was clicked when it was closed or
  // opened with GTM (gtm.click) or Piwik
  listenToThesisAccordionElements() {
    let accordionElements = Array.from(document.getElementsByClassName("toggle_thesis"))
    accordionElements.forEach((element: HTMLInputElement) => {
      //initially add the closed class as the accordion element is closed on init
      element.classList.add("closed")

      //listen to changes of the input and add or remove the classes based on if the input is checked
      element.addEventListener("change", () => {
        if (element.checked) {
          element.classList.add("opened")
          element.classList.remove("closed")
        } else {
          element.classList.add("closed")
          element.classList.remove("opened")
        }
      })
    })
  }

  /*
  * Works as intended
  * Commented out to increase performance as of 05.09.24
  *
  *   scrollToFeedbackIfPossible(el: HTMLElement) {
  *     if (this.page.feedbackCount > 0) {
  *       let topPos = el.offsetTop;
  *       el.scrollTop = topPos;
  *       el.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  *     }
  *   }
  *
  *   scrollToRegister(elementId: string) {
  *     this.viewportScroller.scrollToAnchor(elementId);
  *   }
  *   showMobileStickyButton: boolean = false
  *   scrollPosition: number = 0
  *   @HostListener("window:scroll", []) onWindowScroll() {
  *
  *     const verticalOffset = window.pageYOffset
  *       || document.documentElement.scrollTop
  *       || document.body.scrollTop || 0;
  *     this.scrollPosition = verticalOffset
  *
  *     if (this.page.cronosFormatId != 2314 && this.page.titleTag != "Uniwunder Birthday Bash") return
  *
  *     if(verticalOffset > document.body.offsetHeight - 1500){
  *       this.vanishMobileCta = true
  *     }
  *     else{
  *       this.vanishMobileCta = false
  *     }
  *
  *     if(document.getElementById('RegisterComp') == null) return
  *     if (verticalOffset >
  *       (document.getElementById('RegisterComp').offsetTop - document.getElementById('RegisterComp').offsetHeight)
  *       && this.mediaService.isMobile()
  *     ) {
  *       this.showMobileStickyButton = false
  *     } else {
  *       this.showMobileStickyButton = true
  *     }
  *
  *
  *   }
  *   scrollToTarget(id: string){
  *     let target = document.getElementById(id)
  *     target.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  *   }
  */

}
