import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StudyscriptComponent} from './studyscript.component';
import {BrowserModule} from "@angular/platform-browser";
import {RouterModule, Routes} from "@angular/router";
import {FlexModule} from "@angular/flex-layout";
import {FormsModule} from "@angular/forms";
import {NgxGalleryModule} from "@kolkov/ngx-gallery";
import {MatDividerModule} from "@angular/material/divider";
import {StudyscriptPurchaseConfigComponent} from './studyscript-purchase-config/studyscript-purchase-config.component';
import {
  StudyscriptPrintOptionDialogComponent
} from './studyscript-print-option-dialog/studyscript-print-option-dialog.component';
import {MatButtonModule} from "@angular/material/button";
import {HidInteractionsModule} from "../hid-interactions/hid-interactions.module";
import {StudyscriptGoogleComponent} from './studyscript-google/studyscript-google.component';


const appRoutes: Routes = [
  {path: "druckleistungen", component: StudyscriptComponent},
  {path: "druckleistungen/konfigurator", component: StudyscriptPurchaseConfigComponent}
]

@NgModule({
  declarations: [
    StudyscriptComponent,
    StudyscriptPurchaseConfigComponent,
    StudyscriptPrintOptionDialogComponent,
    StudyscriptGoogleComponent
  ],
  imports: [
    RouterModule.forChild(appRoutes),
    CommonModule,
    BrowserModule,
    FlexModule,
    FormsModule,
    NgxGalleryModule,
    MatDividerModule,
    MatButtonModule,
    HidInteractionsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class StudyscriptModule { }
