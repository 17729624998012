import {Component, Input, OnInit} from '@angular/core';
import {WebinarPlatform} from "../../../../../generated/cronos/data";
import {SafeHtml} from "@angular/platform-browser";
import {MediaService} from "../../../../../media.service";

interface PlatformCardContent {
  imageSrc: string,
  checkmarks: SafeHtml[]
}

@Component({
  selector: 'app-check-in-platform-card',
  templateUrl: './check-in-platform-card.component.html',
  styleUrls: ['./check-in-platform-card.component.scss']
})
export class CheckInPlatformCardComponent implements OnInit {

  @Input() webinarPlatform: WebinarPlatform

  content: Partial<Record<WebinarPlatform, PlatformCardContent>> = {
    GoogleMeet: {
      imageSrc: "assets/img/google-meet-logo.png",
      checkmarks: [
        "Dein Seminar findet via <b>Google Meet</b> statt",
        "Zur Teilnahme brauchst du <b>keinen Account</b>",
        "Du solltest direkt zum Meeting weitergeleitet werden"
      ]
    },
    Teams: {
     imageSrc: "assets/img/teams-logo.png",
      checkmarks: [
        "Dein Seminar findet via <b>Teams</b> statt",
        "Du brauchst <b>keinen Account</b> <br>" +
        "<div class='small-grey-text'>Falls du einen hast, logge dich bitte vorher ein. Bei Problemen kannst du versuchen dich auszuloggen & neu beizutreten oder das Gerät zu wechseln.</div>",
        "Du solltest direkt zum Meeting weitergeleitet werden"
      ]
    },
    Zoom: {
      imageSrc: "assets/img/zoom-logo.png",
      checkmarks: [
        "Dein Seminar findet via <b>Zoom</b> statt",
        "Bitte stelle sicher, dass du die <b>App</b> hast oder plane genug Zeit zum <b>Download</b> ein",
        "Das Meeting sollte dann automatisch öffnen - ganz ohne weitere Meeting-ID oder Code",
        "<b>Alternativ</b> kannst du auch über deinen <b>Browser</b> teilnehmen. <br>" +
        "<div class='small-grey-text'>Lehne dafür den App-Download ab und klicke (erneut) auf „Meeting eröffnen“." +
        "Dann sollte im unteren Bereich der Seite „Haben Sie Probleme mit der Zoom Workplace-App? Mit Ihrem Browser anmelden“ erscheinen. " +
        "Über diesen Link gelangst du zur Meeting-Vorschau (ohne weitere Meeting-ID oder Code).</div>"
      ]
    }
  }

  constructor(public mediaService: MediaService) { }

  ngOnInit(): void {
  }

}
