import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";
import {BugsnagHelperService} from "./bugsnag-helper.service";
import {UserOfflineService} from "./user-offline.service";
import {MetaService} from "./meta.service";
import {AuthService} from "./account/auth.service";

@Injectable({
  providedIn: 'root'
})
export class RequestErrorInterceptorService implements HttpInterceptor {

  constructor(private router: Router,
              private route: ActivatedRoute,
              private bugsnagHelperService: BugsnagHelperService,
              private userOfflineService: UserOfflineService,
              private meteaService: MetaService,
              private authService: AuthService
              ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(err => {
        let rethrow = true;

        if (err instanceof HttpErrorResponse) {
          let showErrorPage = true;
          let errorPagePath = '/error';

          //404 images are not that bad, we need no showErrorPage then!
          if (req.url.indexOf('image') > -1) showErrorPage = false;


          // handle offline users, showErrorPage to error page would be wrong
          if (err.status == 0) {
            this.userOfflineService.notifyUserOffline()
            showErrorPage = false;
            rethrow = false;
          }  else if (err.status != 500) {
            //500 can be seen in backend monitor
            this.bugsnagHelperService.notify(err.status + '-Error from Request to ' + req.url, 'Request-Error', 'REQ-ERROR-FROM' + this.router.url + '---TO---' + req.url)
            rethrow = false;
          }

          if (err.status === 401) {
            this.authService.logout()
            rethrow = true
            showErrorPage = false
          }

          if (err.status === 409) {
            rethrow = true
            showErrorPage = false
          }

          if (err.status === 404 && err.url.includes('/password/reset-request')) {
            rethrow = true
            showErrorPage = false
          }

          if (err.status === 498) {
            showErrorPage = true
            errorPagePath = '/error/invalid-link'
          }

          if (showErrorPage) {
            this.meteaService.setMetaTag("render:status_code", err.status.toString()) // for rendertron
            this.router.navigate([errorPagePath], {queryParamsHandling: 'preserve', skipLocationChange: true})
          }

        }

        if (rethrow) {
          throw err
        } else {
          return of<HttpEvent<any>>()
        }
      }
    ));
  }
}
