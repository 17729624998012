import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

  profilePicChanged$: Observable<any>;
  private profilePicChanged: Subject<any>;
  expandSidenav: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  constructor() {
    this.profilePicChanged = new Subject<any>();
    this.profilePicChanged$ = this.profilePicChanged.asObservable();
  }

  getProfilePicChange(): Observable<any> {
    return this.profilePicChanged$;
  }

  reportProfilePicChange() {
    return this.profilePicChanged.next();
  }

  expand() {
    this.expandSidenav.next(true)
  }
}
