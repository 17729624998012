import {Component, OnInit} from '@angular/core';
import {EnterPromotionContactData, PublicSeminarData} from "../../../generated/cronos/data";
import {PromotionApiResourceV2} from "../../../generated/cronos/resources";
import {PublicFormatPageData} from "../../../generated/cms/data";
import {Router} from "@angular/router";
import {LocalStorageService} from "../../../local-storage.service";
import {GlobalCheckoutService} from "../../../checkout/global-checkout.service";
import Bugsnag from "@bugsnag/js";
import {InternalCookieService} from "../../../internal-cookie.service";


@Component({
  selector: 'app-offer-page-webinar-dates',
  templateUrl: './offer-page-webinar-dates.component.html',
  styleUrls: ['./offer-page-webinar-dates.component.scss']
})
export class OfferPageWebinarDatesComponent implements OnInit {

  seminarDates: PublicSeminarData[] = [];
  buttonText = 'Auswählen';
  selectedPromotionId: number;

  displayWaitingList: boolean = false

  page: PublicFormatPageData = this.localStorageService.getCurrentPage()
  user: EnterPromotionContactData = this.localStorageService.getstoredUserData()

  moreSeminarsRoute: string = '/angebote/' + this.page.routingUrl + '/checkout/seminars/g'

  constructor(
    private promotionApiResourceV2: PromotionApiResourceV2,
    private router: Router,
    private localStorageService: LocalStorageService,
    public globalCheckoutService: GlobalCheckoutService,
    private internalCookieService: InternalCookieService
  ) {
  }

  ngOnInit(): void {
    this.loadSeminarDates();
  }


  loadSeminarDates() {
    if (!this.user?.locationId) return
    this.promotionApiResourceV2.getSeminars({
      locationId: this.user.locationId,
      formatIds: [this.page.cronosFormatId],
      page: 0,
      pageSize: 200,
      maxSeminarsPerDay: 1,
      optimalDaysCount: 3
    }).then(page => {
      this.seminarDates = page

      // display waiting list if there are less than 3 seminar dates
      if (this.seminarDates.length < 3) {
        this.displayWaitingList = true
      }

      // check if user can register to another seminar
      let existingSubscription = this.internalCookieService.getExistingSeminarRegistration(this.page.cronosFormatId)
      if (existingSubscription.length > 0) {
        this.promotionApiResourceV2.getAllowanceForMultipleRegistrationsForLocationAndSeminar(
          {locationId: this.user.locationId, formatId: this.page.cronosFormatId}
        ).then(result => {
          if (result.value == false) {
            this.seminarDates = []
          } else {
            for (let date of this.seminarDates) {
              date.freeSlots = this.calculateRemainingSlots(date.freeSlots);
            }
            if (this.seminarDates.length > 0 || this.displayWaitingList) this.hideOnDatesDisplay()
          }
        })
      } else {
        for (let date of this.seminarDates) {
          date.freeSlots = this.calculateRemainingSlots(date.freeSlots);
        }
        if (this.seminarDates.length > 0 || this.displayWaitingList) this.hideOnDatesDisplay()
      }
    });
  }


  calculateRemainingSlots(slots) {
    if (slots < 4) {
      return slots;
    }
    return Math.max(0, Math.ceil(slots * (3 / 8 + this.nicoCoefficient(slots))));
  }

  nicoCoefficient = places => (places < 4 || places > 10) ? 0 : Math.ceil((places - 2) / 2) / 8;


  selectPromotion(promotion: PublicSeminarData) {
    this.globalCheckoutService.setCurrentPage(this.page);

    this.globalCheckoutService.reportTrackingEvent('InitiateCheckout', {buttonId: 'OfferPageWebinarDate'})

    try {
      let route = '/angebote/' + this.page.routingUrl + '/checkout/seminars/g'

      this.router.navigate([route], {
        queryParams: {psid: promotion.id} ,
        queryParamsHandling: "merge"
      })
    } catch (e) {
      Bugsnag.notify(e)
    }

  }

  getButtonText(promotion: PublicSeminarData): string {
    if (promotion.id == this.selectedPromotionId) {
      return 'Ausgewählt';
    } else {
      return this.buttonText;
    }
  }

  // hides sections or html elements with class 'hide-on-dates-display'
  // if this.seminarDates has got some dates that should be displayed or if the waiting list should be displayed
  hideOnDatesDisplay() {
    let elementsToHide = document.querySelectorAll('.hide-on-dates-display')
    elementsToHide.forEach(element => {
      (element as HTMLElement).style.display = 'none'
    })
  }

  enableWaitingList() {
    this.globalCheckoutService.setCurrentPage(this.page);
    this.globalCheckoutService.reportTrackingEvent('InitiateCheckout', {buttonId: 'OfferPageDatesWaitingList'})

    try {
      let route = '/angebote/' + this.page.routingUrl + '/checkout/seminars/g'

      this.router.navigate([route], {
        queryParams: {waitingList: true} ,
        queryParamsHandling: "merge"
      })
    } catch (e) {
      Bugsnag.notify(e)
    }
  }

}
