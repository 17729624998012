<div *ngFor="let weekItem of segmentedDates">
  <div *ngIf="weekItem.dates.length > 0">
    <div class="week-header w-100" *ngIf="weekItem.weeksFromNow == 0">Diese Woche</div>
    <div class="week-header w-100" *ngIf="weekItem.weeksFromNow == 1">Nächste Woche</div>
    <div class="week-header w-100" *ngIf="weekItem.weeksFromNow > 1">In {{weekItem.weeksFromNow}} Woche<span
      *ngIf="weekItem.weeksFromNow > 1">n</span></div>

    <app-single-seminar-date *ngFor="let seminar of weekItem.dates; let index = index"
                             [seminar]="seminar"
                             [hideBorder]="weekItem.dates.length <= (index + 1)"
                             (selectedSeminar)="selectPromotion($event)">
    </app-single-seminar-date>

  </div>
</div>
