import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PublicFormatPageData} from "../../../../generated/cms/data";
import {EnterPromotionContactData} from "../../../../generated/cronos/data";
import {UtilsService} from "../../../../utils.service";
import {LocalStorageService} from "../../../../local-storage.service";
import {PromotionApiResourceV2} from "../../../../generated/cronos/resources";
import {GlobalCheckoutService} from "../../../global-checkout.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AECDiscCheckoutAEC1ProcessService} from "../aecdisc-checkout-aec1-process.service";
import {AECDiscCheckoutService} from "../../aecdisc-checkout.service";
import {BehaviorSubject} from "rxjs";
import {PhoneNumberFormat, PhoneNumberUtil} from "google-libphonenumber";

@Component({
  selector: 'app-aec1-checkout-user',
  templateUrl: './aec1-checkout-user.component.html',
  styleUrls: ['./aec1-checkout-user.component.scss']
})
export class AEC1CheckoutUserComponent implements OnInit, OnDestroy {

  contactForm: FormGroup;

  page: PublicFormatPageData;
  user: EnterPromotionContactData;

  phoneUtil = PhoneNumberUtil.getInstance();

  lastTouchedFormControlName: string | null = null

  @HostListener('window:beforeunload')
  beforeUnload() {
    this.globalCheckoutService.reportFormCompletionEvent(this.contactForm, this.lastTouchedFormControlName)
  }

  constructor(private fb: FormBuilder,
              private utilsService: UtilsService,
              public localStorageService: LocalStorageService,
              private promotionApiResourceV2: PromotionApiResourceV2,
              public globalCheckoutService: GlobalCheckoutService,
              public aecDiscCheckoutService: AECDiscCheckoutService,
              private router: Router,
              private aec1ProcessService: AECDiscCheckoutAEC1ProcessService,
              private route: ActivatedRoute
  ) {
    this.contactForm = this.fb.group({
      firstName: ['', [this.utilsService.validateName(), Validators.required]],
      lastName: ['', [this.utilsService.validateName(), Validators.required]],
      email: ['', [Validators.email, Validators.required]],
      acceptPrivacy: ['', Validators.requiredTrue],
      acceptAdditionalTerms: [''],
      studyEndYear: [''],
      phoneNumber: [null, [this.utilsService.validatePhoneNumber(), Validators.required]]
    });
  }

  checkoutAttempted = new BehaviorSubject<boolean>(false);
  additionalTermsRequired = false;

  ngOnInit() {
    this.aec1ProcessService.reportTrackingEvent('ContactDataReached');

    this.page = this.globalCheckoutService.getCurrentPage();
    this.user = this.globalCheckoutService.getCurrentUser();
    this.globalCheckoutService.disableForwarding()

    this.promotionApiResourceV2.getPromotionFormat(this.page.cronosFormatId).then(result => {

      this.additionalTermsRequired = result.showConditionsOfParticipation;
    });
    window.scrollTo(0, 0);
    //If competition we need to display terms
    if (this.additionalTermsRequired) {
      this.contactForm.controls.acceptAdditionalTerms.setValidators([Validators.requiredTrue]);
    }

    //Init the form with given contactdata
    this.contactForm.controls['firstName'].setValue(this.user.firstName);
    this.contactForm.controls['lastName'].setValue(this.user.lastName);
    this.contactForm.controls['email'].setValue(this.user.email);
    this.contactForm.controls['phoneNumber'].setValue(this.user.phoneNumber);
    this.contactForm.controls['studyEndYear'].setValue(this.user.studyEndYear);
  }

  ngOnDestroy() {
    this.globalCheckoutService.reportFormCompletionEvent(this.contactForm, this.lastTouchedFormControlName)
  }

  setLastTouchedFormControl(name: string) {
    this.lastTouchedFormControlName = name
  }

  checkingOut = false;

  attemptCheckout() {
    this.checkoutAttempted.next(true);
    if (!this.contactForm.valid) {
      return;
    }
    let parsedPhoneNumber = this.phoneUtil.parseAndKeepRawInput(this.contactForm.controls['phoneNumber'].value);
    this.globalCheckoutService.fillUserData(<EnterPromotionContactData> {
      firstName: this.contactForm.controls['firstName'].value,
      lastName: this.contactForm.controls['lastName'].value,
      email: this.contactForm.controls['email'].value,
      phoneNumber: this.phoneUtil.format(parsedPhoneNumber, PhoneNumberFormat.E164),
      studyEndYear: this.contactForm.controls['studyEndYear'].value,
      privacyConsentGiven: true //consent is mandatory input field
    });
    this.checkingOut = true;
    this.aec1ProcessService.reportTrackingEvent('ContactDataCompleted');
    this.aecDiscCheckoutService.completeCheckout(this.aec1ProcessService.splitIdentifier);
  }

  back() {
    this.router.navigate([this.aec1ProcessService.prepareSplitRoutingUrl(this.route.parent, 'checkout/aec/aec1/location')]);
  }
}
