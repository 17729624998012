import {Injectable} from "@angular/core";
import {PromotionApiResourceV2} from "../../generated/cronos/resources";
import {FeedBackOverviewData} from "../../generated/cronos/data";
import {BehaviorSubject} from "rxjs";
import {PublicOfferPageResource} from "../../generated/cms/resources";


export enum SeminarIdentifier {
    Steuer,
    Thesis,
    Excel,
    Finance,
    Fin4Women,
    All
}

export enum SeminarUrlIdentifier{
    Steuer = "steuerseminar-erfahrungen",
    Thesis = "thesisseminar-erfahrungen",
    Excel = "excel-erfahrungen",
    Finance = "finanzcrashkurs-erfahrungen",
    Fin4Women = "finanzcrashkurs-fuer-frauen-erfahrungen",
    All = ""
}

class FormatToIds {
    type: SeminarIdentifier
    ids: number[]
}

class urlToEnum{
  urlString: SeminarUrlIdentifier
  enumOrdinal : SeminarIdentifier
}

class FormatPageLinkedDataInfo {
  type: SeminarIdentifier
  url: string
  titleTag: string
}

class FormatToFeedbackOverviewData{
    type: SeminarIdentifier
    feedbackData: FeedBackOverviewData
}

@Injectable({
    providedIn: 'root'
})
export class FeedbackSelectorService {

    formatToIds: FormatToIds[] = [
        {type: SeminarIdentifier.Steuer, ids: [1938, 2214]},
        {type: SeminarIdentifier.Thesis, ids: [1950, 2215]},
        {type: SeminarIdentifier.Excel, ids: [1986, 2216]},
        {type: SeminarIdentifier.Finance, ids: [2237]},
        {type: SeminarIdentifier.Fin4Women, ids: [2338]}
    ]

    formatPageLDInfoData: FormatPageLinkedDataInfo[] = [
      {type: SeminarIdentifier.Steuer, url: "", titleTag: "Erfahrungen zu den Steuerseminaren | Hochschulinitiative"},
      {type: SeminarIdentifier.Thesis, url: "", titleTag: "Erfahrungen zu den Thesisseminaren | Hochschulinitiative"},
      {type: SeminarIdentifier.Excel, url: "", titleTag: "Erfahrungen zu den Excelseminaren | Hochschulinitiative"},
      {type: SeminarIdentifier.Finance, url: "", titleTag: "Erfahrungen zu den Finanzseminaren | Hochschulinitiative"},
      {type: SeminarIdentifier.Fin4Women, url: "", titleTag: "Erfahrungen zu den FinEd4Women-Seminaren | Hochschulinitiative"},
      {type: SeminarIdentifier.All , url: null, titleTag: "Erfahrungen zu unseren Seminaren | Hochschulinititive"}
    ]

    seminarFeedbackData: FormatToFeedbackOverviewData[] = [
        {type: SeminarIdentifier.Steuer , feedbackData: <FeedBackOverviewData>{}},
        {type: SeminarIdentifier.Thesis , feedbackData: <FeedBackOverviewData>{}},
        {type: SeminarIdentifier.Excel , feedbackData: <FeedBackOverviewData>{}},
        {type: SeminarIdentifier.Finance , feedbackData: <FeedBackOverviewData>{}},
        {type: SeminarIdentifier.Fin4Women , feedbackData: <FeedBackOverviewData>{}},
        {type: SeminarIdentifier.All , feedbackData: <FeedBackOverviewData>{}},
    ]

  seminarFeedbackDataSubject: BehaviorSubject<FormatToFeedbackOverviewData[]> = new BehaviorSubject(null)

    constructor(public promotionApiResourceV2: PromotionApiResourceV2, private offerPageResource: PublicOfferPageResource) {
        this.prepareAllFormatIds()

    }

    prepareAllFormatIds() {
        let allFormatIds: number[] = []

        this.formatToIds.forEach(it => {
                it.ids.flat().forEach(id => {
                    allFormatIds.push(id)
                })
            }
        )
        this.formatToIds.push({type: SeminarIdentifier.All, ids: allFormatIds})
        this.loadAllData()
    }

    loadAllData(): Promise<void> {
      return Promise.all([this.loadRoutingUrls(), this.loadAverageRatings()]).then(() => {
        this.seminarFeedbackDataSubject.next(this.seminarFeedbackData);
      });
    }

    private loadRoutingUrls(): Promise<void> {
      let promises = this.formatToIds.map(format => {
        if (format.type == SeminarIdentifier.All) return

        return this.offerPageResource.getRoutingUrlForFormatByFormatId(Math.max(...format.ids)).then(result => {
          this.formatPageLDInfoData[format.type].url = result
        })
      })

      return Promise.all(promises).then()
    }

    private loadAverageRatings(): Promise<void> {
      let promises = this.formatToIds.map(format => {
        return this.promotionApiResourceV2.getRatingSummary({formatIds: format.ids}).then(result => {
          this.seminarFeedbackData[format.type].feedbackData = result
        })
      })

      return Promise.all(promises).then()
    }
}
