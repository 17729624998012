import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  NgxGalleryAnimation,
  NgxGalleryComponent,
  NgxGalleryImage,
  NgxGalleryImageSize,
  NgxGalleryOptions
} from "@kolkov/ngx-gallery";
import {MatDialog} from "@angular/material/dialog";
import {
  StudyscriptPrintOptionDialogComponent
} from "./studyscript-print-option-dialog/studyscript-print-option-dialog.component";
import {MediaService} from "../media.service";
import {AnalyticsService} from "../analytics/analytics.service";
import Player from '@vimeo/player'

@Component({
  selector: 'app-studyscript',
  templateUrl: './studyscript.component.html',
  styleUrls: ['./studyscript.component.scss']
})
export class StudyscriptComponent implements OnInit, OnDestroy, AfterViewInit {
  productContentData: StudyScriptProductContent[] = [
    {
      type: 'Thesis',
      heading: !this.mediaService.isMobile() ?
        'Abschlussarbeit professionell drucken <br> & binden lassen' : 'Abschlussarbeit professionell drucken & binden lassen',
      price: '10,12',
      shipping: 'Kostenfreier Versand',
      usp: 'Hochwertige Bindung und das beste Papier',
      details: 'Deine Arbeit ist in 2-3 Werktagen bei dir. Wenn es ganz eilig ist, wähle einfach den Expressversand mit Zustellung innerhalb von 24h.',
      buttonText: 'Jetzt Abschlussarbeit drucken',
      images: [
        {
          small: "assets/img/studyscript/bachelorarbeit.jpg",
          medium: "assets/img/studyscript/bachelorarbeit.jpg",
          big: "assets/img/studyscript/bachelorarbeit.jpg"
        },
        {
          small: "assets/img/studyscript/bachelorarbeit2.jpg",
          medium: "assets/img/studyscript/bachelorarbeit2.jpg",
          big: "assets/img/studyscript/bachelorarbeit2.jpg"
        },
        {
          small: "assets/img/studyscript/bachelorarbeit3.jpg",
          medium: "assets/img/studyscript/bachelorarbeit3.jpg",
          big: "assets/img/studyscript/bachelorarbeit3.jpg"
        },
        {
          small: "assets/img/studyscript/bachelorarbeit4.jpg",
          medium: "assets/img/studyscript/bachelorarbeit4.jpg",
          big: "assets/img/studyscript/bachelorarbeit4.jpg"
        },
        {
          small: "assets/img/studyscript/bachelorarbeit5.jpg",
          medium: "assets/img/studyscript/bachelorarbeit5.jpg",
          big: "assets/img/studyscript/bachelorarbeit5.jpg"
        },
      ]
    },
    {
      type: 'Script',
      heading: 'Skripte ausdrucken & effektiver lernen',
      price: '2,30',
      shipping: 'Versandzeit 3-4 Werktage',
      usp: 'Zufriedenheits- und Bestpreisgarantie',
      details: 'Schon gewusst? 98% aller Studis lernen mit ausgedruckten Skripten besser.*',
      subDetail: "*Studyscript-Umfrage mit 63 Studierenden",
      buttonText: 'Jetzt Skript drucken',
      images: [
        {
          small: "assets/img/studyscript/skript.jpg",
          medium: "assets/img/studyscript/skript.jpg",
          big: "assets/img/studyscript/skript.jpg"
        },
        {
          small: "assets/img/studyscript/skript2.jpg",
          medium: "assets/img/studyscript/skript2.jpg",
          big: "assets/img/studyscript/skript2.jpg"
        },
        {
          small: "assets/img/studyscript/skript3.jpg",
          medium: "assets/img/studyscript/skript3.jpg",
          big: "assets/img/studyscript/skript3.jpg"
        },
        {
          small: "assets/img/studyscript/skript4.jpg",
          medium: "assets/img/studyscript/skript4.jpg",
          big: "assets/img/studyscript/skript4.jpg"
        },
        {
          small: "assets/img/studyscript/skript5.jpg",
          medium: "assets/img/studyscript/skript5.jpg",
          big: "assets/img/studyscript/skript5.jpg"
        }
      ]
    }
  ]

  galleryOptions: NgxGalleryOptions[] = [
    {
      thumbnails: false,
      preview: false,
      arrowPrevIcon: 'mdi mdi-chevron-left',
      arrowNextIcon: 'mdi mdi-chevron-right',
      imageAnimation: NgxGalleryAnimation.Slide,
      width: '100%',
      imageSwipe: true,
      imageSize: NgxGalleryImageSize.Contain,
      imageInfinityMove: true
    }
  ]

  productIndex: number = 0

  studyScriptRatingCount: number = 0
  studyScriptAverageRating: number = 0

  @ViewChild('gallery') gallery: NgxGalleryComponent
  @ViewChild('reviewsSection') reviewsSection: ElementRef
  @ViewChild('video') video: ElementRef<HTMLIFrameElement>
  @ViewChild('player-mobile') videoBg: ElementRef

  player: Player
  playerType: 'Mobile' | 'Desktop'

  @HostListener('window:resize')
  onResize() {
    this.setIFrameSrc()
  }


  constructor(
    private dialog: MatDialog,
    public mediaService: MediaService,
    private analyticsService: AnalyticsService,
  ) {
  }

  ngOnInit() {
    this.loadStudyscriptReviews().then()
  }

  ngAfterViewInit() {
    this.player = new Player(this.video.nativeElement)
    this.setIFrameSrc()
    this.startVideoOnScroll()
  }

  ngOnDestroy() {
  }

  openSelectPrintDialog() {
    this.dialog.open(StudyscriptPrintOptionDialogComponent, {
      width: 'calc(100% - 2em)',
      maxWidth: '55em'
    })
  }

  reportStudyscriptTrackingEvent(context: string) {
    if (this.productContentData[this.productIndex].type == 'Thesis') this.analyticsService.reportRequestThesisPrint(context)
    else this.analyticsService.reportRequestScriptPrint(context)
  }

  onToggleChange(event: any) {
    this.productIndex = event.target.checked ? 1 : 0
  }

  async loadStudyscriptReviews() {
    let { Place } = await google.maps.importLibrary("places") as google.maps.PlacesLibrary
    let place = new Place({
      id: "ChIJdW0oxtijvUcRkSZ19gcwJNw",
      requestedLanguage: "de"
    })

    await place.fetchFields({fields: ['rating', 'userRatingCount']})

    this.studyScriptAverageRating = place.rating
    this.studyScriptRatingCount = place.userRatingCount
  }

  scrollToReviews() {
    this.reviewsSection.nativeElement.scrollIntoView({behavior: "smooth", block: "start"})
  }

  setIFrameSrc() {
    let isDesktop = !this.mediaService.getMaxWidthQuery(800).matches
    let playerType: 'Desktop' | 'Mobile' = isDesktop ? 'Desktop' : 'Mobile'

    if (this.playerType != playerType) {
      this.playerType = playerType
      this.video.nativeElement.src = isDesktop ?
        "https://player.vimeo.com/video/1069998871?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&loop=1&muted=1&controls=1" :
        "https://player.vimeo.com/video/1069999696?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&loop=1&muted=1&controls=1"
    }

  }

  startVideoOnScroll() {
    let observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.player.play()
        } else {
          this.player.pause()
        }
      })
    },
      { threshold: 0.7}
    )

    observer.observe(this.video.nativeElement)
  }

}

export class StudyScriptProductContent {
  type: 'Script' | 'Thesis'
  heading: string
  price: string
  shipping: string
  usp: string
  details: string
  subDetail?: string
  buttonText: string
  images: NgxGalleryImage[]
}
