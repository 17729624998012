<div fxLayout="row" *ngIf="webinarPlatform && webinarPlatform != 'Other'">
  <div class="actionlink-card" [ngClass]="{'cap-card-width': !mediaService.getMaxWidthQuery(1050).matches}"
       fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1.5rem">
    <div class="hint">
      <i class="mdi mdi-alert-circle"></i>
      Hinweis
    </div>

    <img class="platform-image" [src]="content[webinarPlatform].imageSrc" alt="">

    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="1.5rem">
      <div class="checkmark-info" *ngFor="let checkmark of content[webinarPlatform].checkmarks">
        <i class="mdi mdi-check-circle-outline"></i>
        <div [innerHTML]="checkmark"></div>
      </div>
    </div>

  </div>
</div>
