import {Injectable} from '@angular/core';
import {CookieService} from "ngx-cookie-service";
import {GDPRConsent} from './gdprconsent/gdprconsent.service';
import {Subject} from "rxjs";

export class SeminarRegistrationCookieIdentData {
  constructor(
    public seminarId: number,
    public cookieIdent: string) {
  }

}

export class SeminarRegistrationCookieData {
  constructor(
    public registrationDate: Date,
    public  seminarId: number,
    public crnsFormatId: number
  ) {
  }


}

@Injectable({
  providedIn: 'root'
})
export class InternalCookieService {

  signUpCookiesRefreshSubject: Subject<any> = new Subject<any>();

  constructor(private ngxCookieService: CookieService) {
  }


  public getGDPRConsent(): GDPRConsent {
    if (!this.ngxCookieService.check('GDPRConsent')) return null
    return <GDPRConsent>JSON.parse(this.ngxCookieService.get('GDPRConsent'))
  }

  public updateGDPRConsent(consent: GDPRConsent) {
    this.ngxCookieService.set('GDPRConsent', JSON.stringify(consent))
  }

  public storeSeminarCookieIdentifier(seminarId: number, cookieIdentifier: string) {
    this.ngxCookieService.set('SeminarCookieCode-' + seminarId, cookieIdentifier)
  }

  public getExistingSeminarRegistration(crnsFormatId: number): SeminarRegistrationCookieData []{
    let cookieData = this.ngxCookieService.getAll()
    let signUpKeys =  Object.keys(cookieData).filter(x => x.includes("SeminarSignup"))
    return signUpKeys.map(key => <SeminarRegistrationCookieData>JSON.parse(cookieData[key]))
  }



  public getSeminarCookieKeys() {
    let cookieData = this.ngxCookieService.getAll()
    return Object.keys(cookieData).filter(key => key.includes('SeminarCookieCode'))
  }

  public getSeminarCookieIdentifiers() {
    let cookieData = this.ngxCookieService.getAll()
    let seminarCookieKeys = Object.keys(cookieData).filter(key => key.includes('SeminarCookieCode'))
    return seminarCookieKeys.map(key =>
      new SeminarRegistrationCookieIdentData(parseInt(key.split("-")[1]), cookieData[key])
    )

  }
  public deleteSeminarCookieIdentifier(cookieIdent: string) {
    let cookieData = this.ngxCookieService.getAll()
    let seminarCookieKeys = Object.keys(cookieData).filter(key => key.includes('SeminarCookieCode'))
    seminarCookieKeys.map(x => {
      if(cookieData[x]) {
        this.ngxCookieService.delete(x)
      }
    })
  }

  public getFacebookCookies() {
    return {
      fbc: this.ngxCookieService.get('_fbc') || null,
      fbp: this.ngxCookieService.get('_fbp') || null
    }
  }
}
