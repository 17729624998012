  <section *ngIf="user?.locationId" class="date-component-wrapper" fxLayout="row" fxLayoutAlign="center center" id="seminar-dates">
    <div class="seminar-dates-wrapper">
      <h3>
        Sichere dir schnell einen der <b>nächsten freien Termine ✍️</b>
      </h3>

      <app-single-seminar-date class="single-date"
                               *ngFor="let seminar of seminarDates; let index = index"
                               [seminar]="seminar"
                               [hideBorder]="seminarDates.length <= (index + 1)"
                               (selectedSeminar)="selectPromotion($event)">
      </app-single-seminar-date>

      <div class="no-dates-container" *ngIf="displayWaitingList">
        <i class="mdi mdi-calendar-remove-outline"></i>
        <div class="w-100" style="margin-top: 10px;text-align: center" fxLayout="row"
             fxLayout.xs="column" fxLayoutAlign="center center">
          <div style="margin-right: 5px;">Keinen Platz gefunden?</div>
          <div> Jetzt in die <span class="link-button" (click)="enableWaitingList()">Warteliste</span> eintragen.</div>
        </div>
      </div>

      <div class="more-seminars-text" *ngIf="!displayWaitingList">
        <a [routerLink]="moreSeminarsRoute"
           (click)="globalCheckoutService.reportTrackingEvent('InitiateCheckout', {buttonId: 'OfferPageMoreDates'})"
           [queryParamsHandling]="'preserve'">
          Mehr Termine anzeigen
        </a>
      </div>
    </div>
  </section>
